import { javascript } from '@api/all'
import { uniqueArray } from '@avvoka/shared'
import { Config } from '@js-from-routes/client'
import { computed, ref } from 'vue'

// Disable auto conversion from snake_case to camelCase
const noop = (val: unknown) => val
Config.deserializeData = noop
Config.serializeData = noop

export const ensureHydrated = <
  S extends Record<string, unknown> & { hydrated: boolean },
  P extends keyof S
>(
  store: S,
  property: P,
  ...fields: ReadonlyArray<keyof S[P]>
) => {
  if (!store.hydrated) throw new Error('The store is not hydrated')
  if (store[property] == null)
    throw new Error(`The ${String(property)} in the store is not initialized`)
  const missing = fields.filter((item) => store[property][item] === undefined)
  if (missing.length)
    throw new Error(`The fields ${missing.join(', ')} are not hydrated`)
}

export enum StoreMode {
  // The store can be hydrated with new data
  // Means we are editing something that already exists
  EditData,

  // The store cannot be hydrated with new data
  // Means we are creating something new (i.e. a new template)
  NewData
}

export const useHydration = <const HydratedData extends Backend.Models.Model>(
  route: (typeof javascript)[keyof typeof javascript] | null
) => {
  const hydrated = ref(false)
  const loading = ref(false)
  const error = ref(null as unknown)
  const hydratedData = ref<HydratedData | null>(null)
  const storeMode = ref(StoreMode.EditData)
  const hydratedFields = ref<
    ReadonlyArray<keyof HydratedData>
  >([])

  const hydrateFn = async (fn: () => Promise<unknown>) => {
    loading.value = true
    try {
      await fn()
      hydrated.value = true
    } catch (err: unknown) {
      error.value = err
      console.error('Error while hydrating', err)
    } finally {
      loading.value = false
    }
  }

  const hydrate = async (
    parameters: Record<string, string | number>,
    fields: ReadonlyArray<keyof HydratedData>,
    force?: boolean
  ) => {
    if (storeMode.value == StoreMode.NewData) {
      console.warn(
        'hydrate() called on a store in NewData mode; Skipping hydration'
      )
      return
    }

    await hydrateFn(async () => {
      if (!force && hydratedData.value != null) {
        fields = fields.filter(
          (key) => !(key in (hydratedData.value as HydratedData))
        )
      }

      if (fields.length > 0 || force) {
        if (route) {
          const data = await route({
            ...parameters,
            fields
          })
          if (hydratedData.value == null) hydratedData.value = data
          else Object.assign(hydratedData.value, data)
          hydratedFields.value = uniqueArray([
            ...hydratedFields.value,
            ...fields
          ]) as typeof hydratedFields.value
        }
      }
    })
  }

  const hydratedComputed = <
    Key extends keyof HydratedData,
    Return = NonNullable<HydratedData[Key]>
  >(
    property: Key,
    dataMapper: (value: HydratedData[Key]) => Return = (value) =>
      value as Return
  ) => {
    return computed<Return>(() => {
      ensureHydrated(
        { hydrated: hydrated.value, hydratedData: hydratedData.value },
        'hydratedData',
        property as never
      )
      return dataMapper((hydratedData.value as HydratedData)[property])
    })
  }

  const isFieldHydrated = (field: keyof HydratedData) => {
    return hydratedFields.value.includes(field as typeof hydratedFields.value[number])
  }
  return {
    hydrated,
    loading,
    hydrate,
    error,
    hydratedData,
    hydratedComputed,
    hydrateFn,
    storeMode,
    isFieldHydrated
  }
}
