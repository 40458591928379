// JsFromRoutes CacheKey c4f3e194a16b19c664826ba0425b0a96
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { definePathHelper } from '@js-from-routes/axios'

export default {
  chat: definePathHelper('post', '/ai/chat'),
  action: definePathHelper('get', '/ai/action'),
  clear: definePathHelper('post', '/ai/clear')
}
