// JsFromRoutes CacheKey 5598c8a9f510e9d1c17c53b71be9d708
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { definePathHelper } from '@js-from-routes/axios'

export default {
  loggedUser: definePathHelper('get', '/javascript/data/logged-user/:fields'),
  loggedOrganisation: definePathHelper(
    'get',
    '/javascript/data/logged-user/organisation/:fields'
  ),
  loggedParticipant: definePathHelper(
    'get',
    '/javascript/data/logged-participant/:document_id/:fields'
  ),
  template: definePathHelper('get', '/javascript/data/template/:id/:fields'),
  templateVersion: definePathHelper(
    'get',
    '/javascript/data/template-version/:id/:fields'
  ),
  document: definePathHelper('get', '/javascript/data/document/:id/:fields'),
  draft: definePathHelper('get', '/javascript/data/draft/:id/:fields'),
  documentView: definePathHelper(
    'get',
    '/javascript/data/views/document/:document_id'
  ),
  templateView: definePathHelper(
    'get',
    '/javascript/data/views/template/:template_id'
  )
}
